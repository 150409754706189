import hair1 from "./hair1.JPG";
import hair2 from "./hair2.JPG";
import hair3 from "./hair3.JPG";
import hair4 from "./hair4.JPG";
import hair5 from "./hair5.JPG";
import hair6 from "./hair6.JPG";
import hair7 from "./hair7.JPG";
import hair8 from "./hair8.JPG";
import hair9 from "./hair9.JPG";
import hair10 from "./hair10.JPG";
import hair11 from "./hair11.JPG";
import hair12 from "./hair12.JPG";
import hair13 from "./hair13.JPG";
import hair14 from "./hair14.JPG";
import hair15 from "./hair15.JPG";
import hair16 from "./hair16.JPG";
import hair17 from "./hair17.JPG";
import hair18 from "./hair18.JPG";
import hair19 from "./hair19.JPG";
import hair20 from "./hair20.JPG";
import hair21 from "./hair21.JPG";
import hair22 from "./hair22.JPG";
import hair23 from "./hair23.JPG";
import hair24 from "./hair24.JPG";
import hair25 from "./hair25.JPG";
import hair26 from "./hair26.JPG";
import hair27 from "./hair27.JPG";
import hair28 from "./hair28.JPG";
import hair29 from "./hair29.JPG";
import hair30 from "./hair30.JPG";
import hair31 from "./hair31.JPG";
import hair32 from "./hair32.JPG";
import hair33 from "./hair33.JPG";
import hair34 from "./hair34.JPG";
import hair35 from "./hair35.JPG";
import hair36 from "./hair36.JPG";
import hair37 from "./hair37.JPG";
import hair38 from "./hair38.JPG";
import hair39 from "./hair39.JPG";
import hair40 from "./hair40.JPG";
import hair41 from "./hair41.JPG";
import hair42 from "./hair42.JPG";
import hair43 from "./hair43.JPG";
import hair44 from "./hair44.JPG";
import hair45 from "./hair45.JPG";
import hair46 from "./hair46.JPG";
import hair47 from "./hair47.JPG";
import hair48 from "./hair48.JPG";
import hair49 from "./hair49.JPG";
import hair50 from "./hair50.JPG";
import hair51 from "./hair51.JPG";
import hair52 from "./hair52.JPG";
import hair53 from "./hair53.JPG";
import hair54 from "./hair54.JPG";
import hair55 from "./hair55.JPG";
import hair56 from "./hair56.JPG";
import hair57 from "./hair57.JPG";
import hair58 from "./hair58.JPG";
import hair59 from "./hair59.JPG";
import hair60 from "./hair60.JPG";
import hair61 from "./hair61.JPG";
import hair62 from "./hair62.JPG";
import hair63 from "./hair63.JPG";
import hair64 from "./hair64.JPG";
import hair65 from "./hair65.JPG";
import hair66 from "./hair66.JPG";
import hair67 from "./hair67.JPG";
import hair68 from "./hair68.JPG";
import hair69 from "./hair69.JPG";
import hair70 from "./hair70.JPG";
import hair71 from "./hair71.JPG";
import hair72 from "./hair72.JPG";
import hair73 from "./hair73.JPG";
import hair74 from "./hair74.JPG";
import hair75 from "./hair75.JPG";
import hair76 from "./hair76.JPG";
import hair77 from "./hair77.JPG";
import hair78 from "./hair78.JPG";
import hair79 from "./hair79.JPG";
import hair80 from "./hair80.JPG";

export default {
  hair1,
  hair2,
  hair3,
  hair4,
  hair5,
  hair6,
  hair7,
  hair8,
  hair9,
  hair10,
  hair11,
  hair12,
  hair13,
  hair14,
  hair15,
  hair16,
  hair17,
  hair18,
  hair19,
  hair20,
  hair21,
  hair22,
  hair23,
  hair24,
  hair25,
  hair26,
  hair27,
  hair28,
  hair29,
  hair30,
  hair31,
  hair32,
  hair33,
  hair34,
  hair35,
  hair36,
  hair37,
  hair38,
  hair39,
  hair40,
  hair41,
  hair42,
  hair43,
  hair44,
  hair45,
  hair46,
  hair47,
  hair48,
  hair49,
  hair50,
  hair51,
  hair52,
  hair53,
  hair54,
  hair55,
  hair56,
  hair57,
  hair58,
  hair59,
  hair60,
  hair61,
  hair62,
  hair63,
  hair64,
  hair65,
  hair66,
  hair67,
  hair68,
  hair69,
  hair70,
  hair71,
  hair72,
  hair73,
  hair74,
  hair75,
  hair76,
  hair77,
  hair78,
  hair79,
  hair80,
};
