import logo from "./assets/logo-h.png";
import { makeStyles } from "@material-ui/core";
import { Route, Routes } from "react-router-dom";
import { Home, About, Gallery, Contact, Image as GalleryImage } from "./pages";
import { Navbar } from "./components/Navbar";
import { Footer } from "./components/Footer";

export default function App() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Navbar />
        <img className={classes.logo} src={logo} alt="logo" />
      </div>
      <div className={classes.body}>
        <Routes>
          <Route index element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/image/:id" element={<GalleryImage />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    boxSizing: "border-box",
    paddingTop: "3%",
    maxHeight: "90vh",
  },
  header: {
    top: "0",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    zIndex: 1,
    gap: "1.5rem",
  },
  logo: {
    height: "110px",
    width: "auto",
    [theme.breakpoints.up("md")]: {
      height: "140px",
    },
    [theme.breakpoints.up("lg")]: {
      height: "180px",
    },
  },
  body: {
    width: "100%",
    height: "70vh",
  },

  contactCTA: {
    fontFamily: "Architects Daughter",
    fontSize: "1.8rem",
    textAlign: "center",
    color: "#F8E614",
  },
  contactLink: {
    color: "#FFFFFF",
    textShadow: "0px 0px 5px #24A8DF",
    fontSize: "2.6rem",
    letterSpacing: "0.05rem",
    textDecoration: "none",
  },
}));
