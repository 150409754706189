import { useNavigate, useLocation, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import hairImages from "../assets/photos/hair";
// import salonImages from "../assets/photos/salon";

const useStyles = makeStyles(() => ({
  imgContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
    padding: "5% 5% 10%",
    boxSizing: "border-box",
  },
  imageDisplay: {
    maxWidth: "100%",
    maxHeight: "100%",
    borderRadius: "2vh",
    border: "2px solid white",
  },
}));

export default function GalleryImage() {
  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams();
  console.log(params);
  const originals = Object.values(hairImages);
  return (
    <div className={classes.imgContainer} onClick={() => navigate("/gallery")}>
      <img
        src={originals[Number(params.id)]}
        className={classes.imageDisplay}
        onClick={() => navigate("/gallery")}
        alt={"Hairstyle Image"}
      />
    </div>
  );
}
