import hairthumb1 from "./hairthumb1.JPG";
import hairthumb2 from "./hairthumb2.JPG";
import hairthumb3 from "./hairthumb3.JPG";
import hairthumb4 from "./hairthumb4.JPG";
import hairthumb5 from "./hairthumb5.JPG";
import hairthumb6 from "./hairthumb6.JPG";
import hairthumb7 from "./hairthumb7.JPG";
import hairthumb8 from "./hairthumb8.JPG";
import hairthumb9 from "./hairthumb9.JPG";
import hairthumb10 from "./hairthumb10.JPG";
import hairthumb11 from "./hairthumb11.JPG";
import hairthumb12 from "./hairthumb12.JPG";
import hairthumb13 from "./hairthumb13.JPG";
import hairthumb14 from "./hairthumb14.JPG";
import hairthumb15 from "./hairthumb15.JPG";
import hairthumb16 from "./hairthumb16.JPG";
import hairthumb17 from "./hairthumb17.JPG";
import hairthumb18 from "./hairthumb18.JPG";
import hairthumb19 from "./hairthumb19.JPG";
import hairthumb20 from "./hairthumb20.JPG";
import hairthumb21 from "./hairthumb21.JPG";
import hairthumb22 from "./hairthumb22.JPG";
import hairthumb23 from "./hairthumb23.JPG";
import hairthumb24 from "./hairthumb24.JPG";
import hairthumb25 from "./hairthumb25.JPG";
import hairthumb26 from "./hairthumb26.JPG";
import hairthumb27 from "./hairthumb27.JPG";
import hairthumb28 from "./hairthumb28.JPG";
import hairthumb29 from "./hairthumb29.JPG";
import hairthumb30 from "./hairthumb30.JPG";
import hairthumb31 from "./hairthumb31.JPG";
import hairthumb32 from "./hairthumb32.JPG";
import hairthumb33 from "./hairthumb33.JPG";
import hairthumb34 from "./hairthumb34.JPG";
import hairthumb35 from "./hairthumb35.JPG";
import hairthumb36 from "./hairthumb36.JPG";
import hairthumb37 from "./hairthumb37.JPG";
import hairthumb38 from "./hairthumb38.JPG";
import hairthumb39 from "./hairthumb39.JPG";
import hairthumb40 from "./hairthumb40.JPG";
import hairthumb41 from "./hairthumb41.JPG";
import hairthumb42 from "./hairthumb42.JPG";
import hairthumb43 from "./hairthumb43.JPG";
import hairthumb44 from "./hairthumb44.JPG";
import hairthumb45 from "./hairthumb45.JPG";
import hairthumb46 from "./hairthumb46.JPG";
import hairthumb47 from "./hairthumb47.JPG";
import hairthumb48 from "./hairthumb48.JPG";
import hairthumb49 from "./hairthumb49.JPG";
import hairthumb50 from "./hairthumb50.JPG";
import hairthumb51 from "./hairthumb51.JPG";
import hairthumb52 from "./hairthumb52.JPG";
import hairthumb53 from "./hairthumb53.JPG";
import hairthumb54 from "./hairthumb54.JPG";
import hairthumb55 from "./hairthumb55.JPG";
import hairthumb56 from "./hairthumb56.JPG";
import hairthumb57 from "./hairthumb57.JPG";
import hairthumb58 from "./hairthumb58.JPG";
import hairthumb59 from "./hairthumb59.JPG";
import hairthumb60 from "./hairthumb60.JPG";
import hairthumb61 from "./hairthumb61.JPG";
import hairthumb62 from "./hairthumb62.JPG";
import hairthumb63 from "./hairthumb63.JPG";
import hairthumb64 from "./hairthumb64.JPG";
import hairthumb65 from "./hairthumb65.JPG";
import hairthumb66 from "./hairthumb66.JPG";
import hairthumb67 from "./hairthumb67.JPG";
import hairthumb68 from "./hairthumb68.JPG";
import hairthumb69 from "./hairthumb69.JPG";
import hairthumb70 from "./hairthumb70.JPG";
import hairthumb71 from "./hairthumb71.JPG";
import hairthumb72 from "./hairthumb72.JPG";
import hairthumb73 from "./hairthumb73.JPG";
import hairthumb74 from "./hairthumb74.JPG";
import hairthumb75 from "./hairthumb75.JPG";
import hairthumb76 from "./hairthumb76.JPG";
import hairthumb77 from "./hairthumb77.JPG";
import hairthumb78 from "./hairthumb78.JPG";
import hairthumb79 from "./hairthumb79.JPG";
import hairthumb80 from "./hairthumb80.JPG";

export default {
  hairthumb1,
  hairthumb2,
  hairthumb3,
  hairthumb4,
  hairthumb5,
  hairthumb6,
  hairthumb7,
  hairthumb8,
  hairthumb9,
  hairthumb10,
  hairthumb11,
  hairthumb12,
  hairthumb13,
  hairthumb14,
  hairthumb15,
  hairthumb16,
  hairthumb17,
  hairthumb18,
  hairthumb19,
  hairthumb20,
  hairthumb21,
  hairthumb22,
  hairthumb23,
  hairthumb24,
  hairthumb25,
  hairthumb26,
  hairthumb27,
  hairthumb28,
  hairthumb29,
  hairthumb30,
  hairthumb31,
  hairthumb32,
  hairthumb33,
  hairthumb34,
  hairthumb35,
  hairthumb36,
  hairthumb37,
  hairthumb38,
  hairthumb39,
  hairthumb40,
  hairthumb41,
  hairthumb42,
  hairthumb43,
  hairthumb44,
  hairthumb45,
  hairthumb46,
  hairthumb47,
  hairthumb48,
  hairthumb49,
  hairthumb50,
  hairthumb51,
  hairthumb52,
  hairthumb53,
  hairthumb54,
  hairthumb55,
  hairthumb56,
  hairthumb57,
  hairthumb58,
  hairthumb59,
  hairthumb60,
  hairthumb61,
  hairthumb62,
  hairthumb63,
  hairthumb64,
  hairthumb65,
  hairthumb66,
  hairthumb67,
  hairthumb68,
  hairthumb69,
  hairthumb70,
  hairthumb71,
  hairthumb72,
  hairthumb73,
  hairthumb74,
  hairthumb75,
  hairthumb76,
  hairthumb77,
  hairthumb78,
  hairthumb79,
  hairthumb80,
};
