import { Button, makeStyles } from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";

export const Navbar = () => {
  const classes = useStyles();
  const location = useLocation();
  return (
    <div className={classes.navbar}>
      <Link
        to="/"
        className={
          location.pathname === "/" ? classes.linkCurrent : classes.link
        }
      >
        Home
      </Link>
      <Link
        to="/about"
        className={
          location.pathname === "/about" ? classes.linkCurrent : classes.link
        }
      >
        About
      </Link>
      <Link
        to="/gallery"
        className={
          /gallery|image/.test(location.pathname)
            ? classes.linkCurrent
            : classes.link
        }
      >
        Gallery
      </Link>
      <Link
        to="/contact"
        className={
          location.pathname === "/contact" ? classes.linkCurrent : classes.link
        }
      >
        Contact
      </Link>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  navbar: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "center",
    gap: "1.2rem",
    width: "100%",
    fontSize: "1.5rem",
  },
  link: {
    textDecoration: "none",
    fontFamily: "Architects Daughter",
    fontSize: "1.3rem",
    color: "#24A8DF",
    backgroundColor: "transparent",
    letterSpacing: "0.05rem",
    textTransform: "capitalize",
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.8rem",
    },
  },
  linkCurrent: {
    textDecoration: "none",
    fontFamily: "Architects Daughter",
    fontSize: "1.3rem",
    color: "#F8E614",
    textShadow: "0px 0px 2px #F8E614",
    letterSpacing: "0.05rem",
    textTransform: "capitalize",
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.8rem",
    },
  },
}));
