import { makeStyles } from "@material-ui/core";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import thumbs from "../assets/photos/hair/thumbs";

const useStyles = makeStyles((theme) => ({
  galleryContainer: {
    display: "flex",
    flexFlow: "row wrap",
    overflowY: "auto",
    justifyContent: "center",
    height: "60vh",
    maxWidth: "800px",
    margin: "0 auto",
    overflowX: "hidden",
  },
  galleryThumbnail: {
    width: "100px",
    height: "100px",
    borderRadius: "10px",
    margin: "8px",
    border: "2px solid white",
    objectFit: "cover",
    [theme.breakpoints.up("lg")]: {
      height: "155px",
      width: "155px",
    },
  },
}));

export default function Gallery() {
  const navigate = useNavigate();
  const classes = useStyles();
  const buttons = useMemo(
    () =>
      Object.values(thumbs).map((thumbnail, index) => (
        <img
          className={classes.galleryThumbnail}
          key={`thumbnail_${index}`}
          alt={"Hairstyle Image"}
          src={thumbnail}
          onClick={() => navigate(`/image/${index}`)}
          loading="lazy"
        />
      )),
    [thumbs]
  );

  return (
    <div className={`${classes.galleryContainer} custom-scrollbar`}>
      {buttons}
    </div>
  );
}
