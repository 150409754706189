import { makeStyles } from "@material-ui/core";

export default function Contact() {
  const classes = useStyles();
  return (
    <div className={classes.contactContainer}>
      <div className={classes.iframeContainer}>
        <h3
          className={classes.address}
          onClick={() => window.open("https://g.page/gbhstyling?share")}
        >
          Unit 3, 91 Albert St, Preston
        </h3>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3155.055433127548!2d145.02020031631952!3d-37.74184373770645!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad63a2990f419d9%3A0x2e9a4c70274a33ba!2sGet%20Blown%20Hairstyling!5e0!3m2!1sen!2sau!4v1668128413489!5m2!1sen!2sau"
          width="90%"
          className={classes.iframe}
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
      </div>

      <div className={classes.hoursContainer}>
        <p className={classes.info}>
          <span className={classes.day}>Mon</span> <span>10:00 - 19:30</span>
        </p>
        <p className={classes.info}>
          <span className={classes.day}>Tue</span> <span>10:00 - 19:30</span>
        </p>
        <p className={classes.info}>
          <span className={classes.day}>Wed</span> <span>10:00 - 19:30</span>
        </p>
        <p className={classes.info}>
          <span className={classes.day}>Thu</span> <span>10:00 - 23:30</span>
        </p>
        <p className={classes.info}>
          <span className={classes.day}>Fri</span> <span>10:00 - 20:30</span>
        </p>
        <p className={classes.info}>
          <span className={classes.day}>Sat</span> <span>09:30 - 18:30</span>
        </p>
        <p className={classes.info}>
          <span className={classes.day}>Sun</span> <span>11:00 - 16:00</span>
        </p>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  iframeContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "300px",
    maxWidth: "500px",
    [theme.breakpoints.up("lg")]: {
      width: "50%",
      height: "100%",
    },
  },
  iframe: {
    border: 0,
    borderRadius: "2rem",
    height: "100%",
    width: "100%",
    [theme.breakpoints.up("lg")]: {
      height: "80%",
      width: "100%",
    },
  },
  contactContainer: {
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "5%",
    height: "100%",
    fontSize: "1.2rem",
    gap: "1rem",
    [theme.breakpoints.up("lg")]: {
      flexDirection: "row",
      justifyContent: "center",
      fontSize: "1.8rem",
      gap: "10rem",
    },
  },
  hoursContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    width: "100%",
    maxWidth: "500px",
    [theme.breakpoints.up("lg")]: {
      flexDirection: "column",
      width: "auto",
      height: "auto",
      justifyContent: "center",
    },
    paddingTop: "1rem",
    paddingBottom: "1rem",
  },
  info: {
    fontFamily: "Architects Daughter",
    textAlign: "center",
    color: "#FFFFFF",
    letterSpacing: "0.05rem",
    display: "flex",
    justifyContent: "space-between",
    gap: "1rem",
    width: "10rem",
    fontSize: "1.1rem",
    marginBlockStart: 0,
    marginBlockEnd: 0,
    [theme.breakpoints.up("lg")]: { width: "20rem", fontSize: "1.5rem" },
  },
  address: {
    fontFamily: "Architects Daughter",
    textAlign: "center",
    color: "#FFFFFF",
    marginBottom: "2vh",
    letterSpacing: "0.05rem",
  },
  day: {
    fontWeight: "bold",
  },
}));
