import { makeStyles } from "@material-ui/core";
import { Email, Facebook, Instagram } from "@material-ui/icons";

export const Footer = () => {
  const classes = useStyles();
  return (
    <div className={classes.footer}>
      <a className={classes.contact} href="tel:+61391937944">
        (03) 9193-7944
      </a>
      <a className={classes.socialBtn} href="mailto:info@getblownhair.biz">
        <Email />
      </a>
      <a
        className={classes.socialBtn}
        href="https://www.facebook.com/gbhstyling"
      >
        <Facebook />
      </a>
      <a
        href="https://www.instagram.com/gbh_styling"
        className={classes.socialBtn}
      >
        <Instagram />
      </a>
    </div>
  );
};
const useStyles = makeStyles((theme) => ({
  footer: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "center",
    gap: "2rem",
    width: "100%",
    fontSize: "1.5rem",
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.8rem",
    },
  },
  contact: {
    fontFamily: "Architects Daughter",
    color: "#24A8DF",
    textAlign: "center",
    textDecoration: "none",
    letterSpacing: "0.05rem",
  },
  socials: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    width: "100%",
    height: "5vh",
  },
  socialBtn: {
    color: "#24A8DF",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
}));
