import { makeStyles } from "@material-ui/core";
//@ts-ignore
import { useNavigate } from "react-router-dom";
import { ImageCarousel } from "../components";

import hair from "../assets/photos/hair";

export default function Home() {
  const classes = useStyles();
  const navigate = useNavigate();

  console.log(Object.values(hair));

  const imgs: string[] = Object.values(hair).slice(0, 10);

  return (
    <div className={classes.home}>
      <div
        className={classes.carouselContainer}
        onClick={() => navigate("/gallery")}
      >
        <ImageCarousel
          slides={imgs}
          autoplay={true}
          interval={3000}
          // arrows={false}
        />
      </div>

      <h3 className={classes.contactCTA}>
        <a className={classes.contactLink} href="tel:+61391937944">
          CALL NOW
        </a>
        <br />
        for a free phone consultation
      </h3>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  home: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    height: "100%",
  },
  carouselContainer: {
    marginTop: "8vh",
    height: "30vh",
    fontSize: "2rem",
    borderRadius: "2vh",
    marginBottom: "4vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  contactCTA: {
    fontFamily: "Architects Daughter",
    fontSize: "1.5rem",
    textAlign: "center",
    color: "#F8E614",
    letterSpacing: "0.05rem",
    fontWeight: 400,
  },
  contactLink: {
    color: "#FFFFFF",
    textShadow: "0px 0px 2px #24A8DF",
    fontSize: "2.2rem",
    letterSpacing: "0.25rem",
    textDecoration: "none",
    fontWeight: 700,
  },
}));
